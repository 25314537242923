<template>
  <v-dialog v-model="dialog" max-width="390" persistent>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" outline color="primary" small >
        <v-icon>qr_code_scanner</v-icon>
        <span style="padding-left: 5px;">Conectar</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-text style="text-align: center">
        <WiLoading
          :loading="channel.status_id == 0"
          message="Iniciando WhatsApp...<br>Por favor aguarde."
          :subMessage="`Timeout: ${initializingSecondsToExpire} segundos`"
        >
          <div style="
            text-align: center;
            gap: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          ">
            <div style="
              min-width: 270px !important;
              min-height: 270px !important;
              max-width: 270px;
              border: 1px gray solid;
              text-align: center;
              border-radius: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            ">
              <div v-if="channel.status_id == 4">
                <v-img v-if="channel.qrcode_url" :src="channel.qrcode_url"></v-img>
                <h3 v-else>Criando QRCode...<br>Por favor aguarde.</h3>
                <div v-if="qrCodeSecondsToExpire > 0">
                  <span>Tempo para expiração do QRCode: </span>
                  <br/>
                  <strong style="font-size: 20px;" :style="{color: qrCodeSecondsToExpire < 15 ? 'red' : (qrCodeSecondsToExpire < 25 ? 'orange' : 'blue')}">
                    <span>{{ qrCodeSecondsToExpire }} </span>
                    <span style="width: 95px;">
                      <span> segundos</span>
                    </span>
                  </strong>
                </div>
                <div v-else>
                  <strong style="font-size: 20px; color: orange;">
                    QRCode expirado...
                  </strong>
                  <br>
                  <span>Reiniciando WhatsApp, por favor aguarde.</span>
                </div>
              </div>
              <div v-else>
                <v-icon v-if="channel.status" x-large :color="channel.status.color">
                  {{ channel.status.icon }}
                </v-icon>
              </div>
            </div>
            <div>
              <h3 v-if="channel.status_id == -2">Falha ao iniciar WhatsApp.<br>Tente novamente ou contacte o suporte.</h3>
              <h3 v-if="channel.status_id == -1">Erro na leitura ou QRCode expirado</h3>
              <h3 v-if="channel.status_id == 1">WhatsApp encerrado</h3>
              <h3 v-if="channel.status_id == 2" style="color: green;">WhatsApp conectado</h3>
              <h3 v-if="channel.status_id == 3">WhatsApp iniciado<br>desconectado</h3>
              <h3 v-if="channel.status_id == 4">WhatsApp iniciado<br>Aguardando leitura do QRCode</h3>
            </div>
            <div style="width: 170px;">
              <v-btn
                v-if="channel.status_id <= 1"
                outline block
                @click="getWhatsAppQrCode()"
                :loading="loadingConfirm"
              >
                <v-icon>qr_code</v-icon>
                <span style="padding-left: 5px;"> Novo Código</span>
              </v-btn>
              <v-btn color="red darken-1" outline block @click="dialog = false" >
                <v-icon>logout</v-icon>
                <span style="padding-left: 5px;"> Cancelar</span>
              </v-btn>
            </div>
          </div>
        </WiLoading>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import WiLoading from '@/default/component/WiLoading/WiLoading'
  export default {
    name: 'WhatsAppQRCode',
    data: () => ({
      dialog: false,
      message: null,
      loading: false,
      qrCodeUrl: null,
      loadingConfirm: false,
      talkChannel: {
        status_id: 1,
        status: {
          icon: 'cloud_off',
          color: 'gray'
        }
      },
      qrCodeSecondsToExpire: 50,
      stage: '',
      initializingSecondsToExpire: 120
    }),
    methods: {
      getWhatsAppQrCode: function () {
        this.loading = true
        this.qrCodeUrl = null
        this.loadingConfirm = true
        this.message = 'Buscando QR Code, por favor aguarde!'
        this.$WiApiGet({config: {askMsg: true}, uri: `talks/channel/${this.channel.id}/connect`, callback: response => {
          if (response.success) {
            this.qrCodeUrl = response.success.data.url
            this.message = 'Por favor, leia o QR Code com seu aplicativo do WhatsApp!'
          } else {
            // this.message = 'Erro ao buscar QR Code tente novamente ou entre em contato com o suporte técnico!'
            this.message = 'Criando QRCode... Por favor aguarde.'
          }
          this.loading = false
        }})
        this.talkChannel = {
          status_id: 0
        }
        this.qrCodeSecondsToExpire = 50
        this.initializingSecondsToExpire = 50
        let initializingSecondsToExpireInterval = setInterval(() => {
          this.initializingSecondsToExpire--
          if (this.initializingSecondsToExpire <= 0 && this.talkChannel.status_id == 0) {
            this.talkChannel = {
              status_id: -2,
              status: {
                icon: 'close',
                color: 'red'
              }
            }
            clearInterval(initializingSecondsToExpireInterval)
          }
          if (this.talkChannel.status_id != 0) {
            clearInterval(initializingSecondsToExpireInterval)
          }
        }, 1000);
      },
      confirmChannelStatus: function () {
        this.loadingConfirm = true
        this.message = 'Confirmando QR Code, por favor aguarde!'
        this.$WiApiGet({
          uri: `talks/channel/${this.channel.id}/connected`,
          callback: response => {
            console.log(response, 'response')
            if (response.success && response.success.status && response.success.status === 'success') {
              this.$swal({
                type: 'success',
                title: 'Tudo certo!',
                text: 'Canal ativado com sucesso.'
              })
            } else {
              this.message = 'Canal ainda não está ativo, tente novamente ou contact o suporte!'
            }
            this.refresh()
            this.loadingConfirm = false
          }
        })
      },
      subscribeInTalkChannel: function () {
        window.Echo.private(this.$Auth.account().id + '.talk-channel.' + this.channel.id)
        .listen('.status', (status) => {
          console.log(status)
          if (status.status_id == 4) {
            this.getWhatsAppQrCode()
            this.loadingConfirm = false
          } else if (status.status_id == 2) {
            this.loadingConfirm = false
          } else {
            this.loadingConfirm = true
          }
          if (status.status_id != 0) {
            this.confirmChannelStatus()
            this.$emit('refreshStatus')
          }
          this.talkChannel = status
          let interval = null
          if (status.status_id == 4) {
            interval = setInterval(() => {
              if (this.qrCodeSecondsToExpire > 0) {
                this.qrCodeSecondsToExpire--
              } else {
                clearInterval(interval)
              }
            }, 1000)
          } else {
            clearInterval(interval)
          }
        })
      },
      unsubscribeOnTalk: function () {
        window.Echo.leave(this.$Auth.account().id + '.talk-channel.' + this.channel.id)
      }
    },
    mounted: function () {
      this.subscribeInTalkChannel()
    },
    beforeDestroy: function () {
      this.unsubscribeOnTalk()
    },
    props: {
      channel: Object,
      refresh: Function
    },
    components: {
      WiLoading
    }
  }
</script>
<style>

</style>